<template>
  <v-dialog
    v-model="isVisible"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title class="text-h5">
        {{ msg }}?
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="toggle('close')"
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="toggle('ok')"
        >
          {{ $t("ok") }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isVisible: Boolean,
    toggle: {
      type: Function,
      default() {
        return void 0;
      },
    },
    msg: {
      type: String,
      default() {
        return this.$t("delete_confirmation_message");
      },
    },
    row: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    async deleteItemConfirm() {
      //   try {
      //     await this.$store.dispatch("mdMakes/destroy", row);
      //     this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
      //     this.toggleDelete();
      //   } catch (error) {
      //     this.$store.dispatch("alerts/error", error.response?.data?.message);
      //   }
    },
  },
};
</script>